<template>
    <div class="vx-col md:w-full sm:w-auto text-center">
        <vx-tooltip :text="$t('button.cancel')" position="top" v-if="params.data.registerPackageStatus == 0">
            <feather-icon icon="XIcon" class="icon-action1"
                          svgClasses="h-5 w-5 mr-4 hover:text-danger cursor-pointer"
                          @click="confirmDeleteRecord"/>
        </vx-tooltip>
    </div>
</template>

<script>
export default {
    name: 'CellRendererActions',
    methods: {
        confirmDeleteRecord() {
            this.$vs.dialog({
                type: 'confirm',
                color: 'danger',
                title: `Xác nhận hủy`,
                text: `Bạn có chắc là muốn hủy bỏ yêu cầu nâng cấp gói "${this.params.data.organizationPackageResponseDTO.packageName}" không ?`,
                accept: this.deleteRecord,
                acceptText: "Xoá",
                cancelText: "Huỷ"
            })
        },

        deleteRecord() {
            this.$vs.loading();
            this.$crm.post(`/organization-package-history/cancel/${this.params.data.id}`).then(() => {
                this.$vs.loading.close();
                return this.showDeleteSuccess();
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },

        showDeleteSuccess() {
            this.$store.commit('UPDATE_REQUEST_PACKAGE', true);
            this.$vs.notify({
                color: 'success',
                text: 'Hủy nâng cấp gói thành công.',
                iconPack: 'feather',
                icon: 'icon-alert-circle',
            })
        }
    }
}
</script>
<style scoped>
.icon-center {
    text-align: center;
}

.icon-action1 {
    color: red;
}

.icon-action1:hover {
    color: red;
    opacity: 0.5;
}

</style>

