<template>
    <div id="page-wifi-list">
        <div class="vx-card p-6">
            <div class="flex flex-wrap items-center">
                <div class="flex-grow">

                    <span>{{$t('menuPackage.titlePackage')}}</span>

                </div>
                <!-- ITEMS PER PAGE -->
                <Pagination
                    :itemList="packageHistory"
                    :currentPage="currentPage"
                    :paginationPageSize="paginationPageSize"
                    :gridApi="gridApi"
                />

                <vs-input class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4"
                          v-model="searchQuery"
                          @input="updateSearchQuery" :placeholder="$t('menuPackage.search')"/>
            </div>
            <!-- AgGrid Table -->
            <ag-grid-vue style="height: 55vh"
                         ref="agGridTable"
                         :components="components"
                         :gridOptions="gridOptions"
                         class="ag-theme-material w-100 my-4 ag-grid-table"
                         :columnDefs="columnDefs"
                         :defaultColDef="defaultColDef"
                         :rowData="packageHistory"
                         :animateRows="true"
                         :pagination="true"
                         :localeText=" {noRowsToShow: this.$t('noRowsToShow')}"
                         :paginationPageSize="paginationPageSize"
                         :onGridReady="onGridReady"
                         :suppressPaginationPanel="true">
            </ag-grid-vue>

            <vs-pagination
                :total="totalPages"
                :max="maxPageNumbers"
                v-model="currentPage"/>
        </div>
    </div>
</template>

<script>
import {AgGridVue} from "ag-grid-vue"
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import Pagination from "../../ui-elements/Pagination.vue"
import CellRendererActions from "./cell-renderer/CellRendererActions.vue"
import CellRendererStatus from "./cell-renderer/CellRendererStatus";
import moment from 'moment'

export default {
    components: {
        AgGridVue,

        // Cell Renderer
        CellRendererActions,
        Pagination,
        CellRendererStatus
    },
    data() {
        return {
            searchQuery: "",
            maxPageNumbers: 7,
            // AgGrid
            gridApi: null,
            gridOptions: {},
            defaultColDef: {},
            columnDefs: [
                {

                    headerName: this.$t('menuPackage.softwarePackage'),

                    field: 'organizationPackageResponseDTO.packageName',
                    width: 200,
                },
                {

                    headerName: this.$t('menuPackage.requestDate'),

                    field: 'startDuration',
                    width: 200
                },
                {

                    headerName: this.$t('menuPackage.price'),

                    field: 'price',
                    width: 200
                },
                {

                    headerName: this.$t('menuPackage.status'),

                    field: 'registerPackageStatus',
                    width: 200,
                    cellRendererFramework: 'CellRendererStatus'
                },
                {

                    headerName: this.$t('menuPackage.actions'),

                    field: 'actions',
                    width: 90,
                    cellRendererFramework: 'CellRendererActions',
                },
            ],
            // Cell Renderer Components
            components: {
                CellRendererActions,
                CellRendererStatus
            },
            packageHistory: [],
            branchId: null,
        }
    },
    created() {
        this.initScreen();
        this.$eventBus.$on('i18n', (event) =>{
            this.columnDefs[0].headerName =  this.$t('menuPackage.softwarePackage');
            this.columnDefs[1].headerName =  this.$t('menuPackage.requestDate');
            this.columnDefs[2].headerName =  this.$t('menuPackage.price');
            this.columnDefs[3].headerName =  this.$t('menuPackage.status');
            this.columnDefs[4].headerName =  this.$t('menuPackage.actions');
            this.gridOptions.api.setColumnDefs(this.columnDefs);
        })
    },
    watch: {
        '$store.state.windowWidth'(val) {
            if (val <= 1024) {
                this.maxPageNumbers = 5;
            } else {
                this.maxPageNumbers = 7;
            }

            if (val > 1024) {
                this.gridApi.sizeColumnsToFit();
            }
        },
        '$store.state.reduceButton'() {
            setTimeout(() => {
                this.gridApi.sizeColumnsToFit();
            }, 650);
        },
        '$store.state.isUpdateOrgPackageRequestPackage'(val) {
            if (val) {
                this.initScreen();
                this.$store.commit('UPDATE_REQUEST_PACKAGE', false);
            }
        },
    },
    computed: {
        paginationPageSize() {
            if (this.gridApi) return this.gridApi.paginationGetPageSize();
            else return 20
        },
        totalPages() {
            if (this.gridApi) return this.gridApi.paginationGetTotalPages();
            else return 0
        },
        currentPage: {
            get() {
                if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
                else return 1
            },
            set(val) {
                this.gridApi.paginationGoToPage(val - 1)
            }
        }
    },
    methods: {
        updateSearchQuery(val) {
            this.gridApi.setQuickFilter(val)
        },
        addWifi() {
            let url = '/user/add-wifi';
            if (this.branchId)
                url = `${url}?branch_id=${this.branchId}`;

            this.$router.push(url).catch(() => {
            })
        },
        onGridReady() {
            if (this.$store.state.windowWidth <= 1024) {
                this.maxPageNumbers = 5;
            } else {
                this.maxPageNumbers = 7;
            }

            if (this.$store.state.windowWidth > 1024) {
                this.gridApi.sizeColumnsToFit();
            }
        },
        initScreen() {
            this.$vs.loading();
            let url = '/camera-package-history/find-by-org';
            this.$crm.get(url).then((response) => {
                this.packageHistory = response.data.map(x => {
                    x.startDuration = moment(x.startDuration).format("DD/MM/YYYY HH:mm:ss");
                    return x;
                })
                this.$vs.loading.close();
                setTimeout(() => {
                    if (this.$store.state.windowWidth > 1024) {
                        this.gridApi.sizeColumnsToFit();
                    }
                }, 100);
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },
    },
    mounted() {
        this.gridApi = this.gridOptions.api;
    }
}
</script>

<style scoped>

</style>
